import axios from "axios"
import loginService from "./loginService"

const baseUrl = ""

const saveAttachement = async (meta, file) => {
  console.log("SAVE " + JSON.stringify(meta))
  console.log("token " + loginService.getConfig())

  const filename = meta.filename.replace(/[^\w\.]/gu, "")

  const config = {
    headers: {
      ...loginService.getConfig().headers,
      "Content-Type": meta.type,
      Filename: filename,
    },
  }

  console.log("Config " + JSON.stringify(config))

  const response = await axios.post(baseUrl + "/attachments", file, config)
  return response.data
}

export default { saveAttachement }
