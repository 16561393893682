import axios from "axios"
import loginService from "./loginService"

const baseUrl = ""

const saveInvoice = async (invoice, attachments) => {
  console.log("Liitteet " + JSON.stringify(attachments))
  invoice.attachments = attachments.map((item) => parseInt(item.id))
  console.log("SAVE INVOICE " + JSON.stringify(invoice))

  const response = await axios.post(
    baseUrl + "/documents",
    invoice,
    loginService.getConfig()
  )
  return response.status === 201
}

export default { saveInvoice }
